 function IdCard(IdCard, type) {
	if (type === 1) {
		//获取出生日期
		let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
		return birthday
	}
	if (type === 2) {
		//获取性别
		if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
			return '1'
		} else {
			return '0'
		}
	}
	if (type === 3) {
		//获取年龄
		var ageDate = new Date()
		var month = ageDate.getMonth() + 1
		var day = ageDate.getDate()
		var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
		if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) == month && IdCard.substring(12, 14) <= day) {
			age++
		}
		if (age <= 0) {
			age = 1
		}
		return age
	}
}


export{
	IdCard
}
